import { Component, HostListener, OnInit } from "@angular/core";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import { Router, RouterModule } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MENUITEMS } from "../../app.routes";
import { MenuItem } from "../../models/menu-item";
import { MatMenuModule } from "@angular/material/menu";
import { AuthService } from "../../services/auth.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { LocalStorageEnum } from "../../models/enums/local-storage-enum";
import { MatDialog } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import { BaseDialogData } from "../../models/bases/base-dialog-data";
import { DomSanitizer } from "@angular/platform-browser";
import { UserChangePwdFormComponent } from "../../routes/users/components/user-change-pwd-form/user-change-pwd-form.component";
import { NgIf } from "@angular/common";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OrganizationModel } from "../../models/organization";
import { MatRipple } from "@angular/material/core";

@Component({
  selector: "app-main-container",
  standalone: true,
  imports: [
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    RouterModule,
    TranslateModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    NgIf,
    MatRipple,
  ],
  templateUrl: "./main-container.component.html",
  styleUrl: "./main-container.component.scss",
})
export class MainContainerComponent implements OnInit {
  currentLanguage: string = "English"; // Default language
  username: string;
  role: string;
  opened: boolean = true;
  organzation: OrganizationModel = null;
  menu: any = {};
  menuList: MenuItem[] = [];
  settingsMenu: MenuItem[];
  desktopViewWidth: number = 1100;
  drawerMode: "over" | "side" = "side";

  @HostListener("window:resize", ["$event.target.innerWidth"])
  onResize(width: number) {
    if (width < this.desktopViewWidth) {
      this.drawerMode = "over";
      if (this.opened) {
        this.opened = false;
      }
    } else {
      this.drawerMode = "side";
      if (!this.opened) {
        this.opened = true;
      }
    }
  }

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private _authService: AuthService,
    private _localStorageService: LocalStorageService,
    private _router: Router,
    private _dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    iconRegistry.setDefaultFontSetClass("material-icons-outlined");
    iconRegistry.addSvgIcon(
      "home",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/home.svg"),
    );
    iconRegistry.addSvgIcon(
      "about",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/about.svg"),
    );
    iconRegistry.addSvgIcon(
      "account",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/account.svg"),
    );
    iconRegistry.addSvgIcon(
      "accountant",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/accountant.svg"),
    );
    iconRegistry.addSvgIcon(
      "carbon_report",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/carbon_report.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "dollar_cycle",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/dollar_cycle.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "investment_plan",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/investment-plan.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "invest_dollar",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/invest_dollar.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "request",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/request.svg"),
    );
    iconRegistry.addSvgIcon(
      "settings",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/solar-settings-linear.svg",
      ),
    );
  }

  ngOnInit(): void {
    this.username = this._localStorageService.get(LocalStorageEnum.username);
    this.role = this._localStorageService.get(LocalStorageEnum.user_role);
    const savedLanguage = this._localStorageService.get(
      LocalStorageEnum.language,
    );
    this.currentLanguage = savedLanguage === "km" ? "Khmer" : "English";
    this.translate.use(savedLanguage || "en");
    this.username = this._localStorageService.get(LocalStorageEnum.username);
    const permissions = this._authService.permissions;
    this.organzation = this._authService.organization;
    // console.log("org", this.organzation);
    // console.log("role", this.role);
    for (let item of MENUITEMS) {
      if (item.roles.includes(this.role)) {
        if (item.show !== false) {
          if (this.role != "systemAdmin") {
            if (
              item.type == "meetings" &&
              this.organzation.useMeeting == "no"
            ) {
              continue;
            } else if (
              item.type == "missions" &&
              this.organzation.useMission == "no"
            ) {
              continue;
            } else if (
              item.type == "leaders" &&
              this.organzation.useLeader == "no"
            ) {
              continue;
            } else if (
              item.type == "leave_requests" &&
              this.organzation.useLeave == "no"
            ) {
              continue;
            } else if (
              item.type == "announcements" &&
              this.organzation.useAnnouncement == "no"
            ) {
              continue;
            } else if (
              (item.type == "meetings_request" ||
                item.type == "officers" ||
                item.type == "offices") &&
              this.organzation.useMeetingRequest == "no"
            ) {
              continue;
            } else if (
              item.type == "documents" &&
              this.organzation.useDocument == "no"
            ) {
              continue;
            } else if (
              item.type == "users" &&
              this.organzation.useMobile == "no"
            ) {
              continue;
            } else if (
              item.type == "reports" &&
              this.organzation.useReport == "no"
            ) {
              continue;
            } else if (
              item.type == "tokens" &&
              this.organzation.manageAuthorization == "no"
            ) {
              continue;
            } else if (
              item.type == "display" &&
              this.organzation.showTvLink == "no"
            ) {
              continue;
            }
          }
          this.menuList.push(item);
        }
      }
    }
    // sort menuList by index
    this.menuList.sort((a, b) => a.index - b.index);
    if (this._router.url === "/") {
      const firstAvailableMenu = MENUITEMS.find((item: MenuItem) => {
        return item.permissions?.find((permission) =>
          permissions.includes(permission),
        );
      });
      if (firstAvailableMenu) {
        console.log("first available", firstAvailableMenu);
        this._router.navigate([firstAvailableMenu.route], {
          replaceUrl: true,
        });
      } else {
        this._router.navigate(["/login"], {
          queryParams: {
            errors: "UnauthorizedPermission",
          },
        });
      }
    }
  }

  toggleMenu() {
    this.opened = !this.opened;
  }

  userChangePwdHandler = async () => {
    try {
      const dialogData: BaseDialogData = {
        isForUpdate: false,
      };
      const dialogRef = this._dialog.open(UserChangePwdFormComponent, {
        width: "600px",
        disableClose: true,
        data: dialogData,
        panelClass: "custom-dialog",
      });
      const result = await lastValueFrom(dialogRef.afterClosed());
      if (result && result.succeeded) {
        console.log("userChangePwd succeeded => ", result.succeeded);
        this._authService.logout();
      } else {
        console.log("user change pwd error");
      }
    } catch (error) {
      // this._messageService.showError(error);
      console.log("user change pwd error->", error);
      this.snackBar.open("Change password Error", "Close", {
        duration: 2000,
      });
    }
  };

  logoutHandler() {
    this._authService.logout();
  }

  switchLanguage(language: string): void {
    if (language === "en") {
      this.currentLanguage = "English";
    } else if (language === "km") {
      this.currentLanguage = "Khmer";
    }
    this.translate.use(language);
    this._localStorageService.set(LocalStorageEnum.language, language);
  }
}
